<template>
  <b-card
    v-if="planData"
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        Current Plan
      </h5>
      <b-badge variant="light-primary">
        {{ planData.name }}
      </b-badge>
      <small class="text-muted w-100">{{ new Date(planData.valid_date) | moment("D MMMM YYYY") }}</small>
    </b-card-header>

    <b-card-body>
      <ul class="list-unstyled my-1">
        <li>
          <span class="align-middle">{{ planData.spec1 }}</span>
        </li>
        <li>
          <span class="align-middle">{{ planData.spec2 }}</span>
        </li>
        <li>
          <span class="align-middle">{{ planData.spec3 }}</span>
        </li>
      </ul>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
      >
        Upgrade Plan
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import userStoreModule from '../userStoreModule'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const planData = ref(null)

    const STORE_MODULE_NAME = 'user-store'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    store
      .dispatch('user-store/getUserPricingPlan', { code: props.userData.general.pricing_plan_code })
      .then(response => {
        planData.value = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          planData.value = undefined
        }
      })

    return {
      planData,
    }
  },
}
</script>

<style>

</style>
