<template>
  <b-card
    no-body
  >
    <b-card-body>
      <b-card-title>{{ $t('Permission') }}</b-card-title>
      <b-card-sub-title>{{ $t('Permission according to roles') }}</b-card-sub-title>
    </b-card-body>
    <b-table
      striped
      responsive
      :items="userData.permission.permissions"
      :fields="permissionsTableColumns"
      class="mb-0"
    >

      <template #cell(module)="data">
        {{ $t(data.value) }}
      </template>
      <template #cell()="data">
        <b-form-checkbox
          disabled
          :checked="data.value"
        />
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { t } from '@/@core/libs/i18n/utils'

export default {
  components: {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const permissionsTableColumns = [
      { key: 'module', label: t('module') },
      { key: 'read', label: t('read') },
      { key: 'write', label: t('write') },
      { key: 'create', label: t('create') },
      { key: 'delete', label: t('delete') },
    ]

    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    return {
      permissionsTableColumns,
      permissionsData,
    }
  },
}
</script>

<style>

</style>
