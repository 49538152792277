import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/center/get_users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getUser(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/center/get_user', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/center/add_user', params.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editUser(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/center/edit_user/${params.id}`, params.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/center/delete_user/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImageUser(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/center/upload_image_user/${params.id}`, params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getUserPricingPlan(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/center/get_user_pricing_plan', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDefaultUser(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/center/get_default_user', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getUserOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/center/user_options', params.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
