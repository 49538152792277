<template>
  <b-card>

    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.general.avatar"
            :text="avatarText(userData.general.name)"
            :variant="`light-${resolveUserRoleVariant(userData.general.role)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.general.name }}
              </h4>
              <span class="card-text">{{ userData.general.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'user-edit', params: { id: $router.currentRoute.params.id } }"
                variant="primary"
              >
                {{ $t('Edit') }}
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
                @click="deleteUser"
              >
                {{ $t('Delete') }}
              </b-button>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="TagIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                12000
              </h5>
              <small>Broadcast Monthly</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="RepeatIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                4
              </h5>
              <small>Share Monthly</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('Username') }}</span>
            </th>
            <td class="pb-50">
              {{ userData.general.username }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('Gender') }}</span>
            </th>
            <td class="pb-50">
              {{ $t(textFirstUpper(userData.info.gender)) }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('Status') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge
                pill
                :variant="`light-${resolveUserStatusVariant(userData.general.status)}`"
                class="text-capitalize"
              >
                {{ $t(textFirstUpper(userData.general.status)) }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('Role') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              <div class="text-nowrap">
                <feather-icon
                  :icon="resolveUserRoleIcon(userData.general.role)"
                  size="18"
                  class="mr-50"
                  :class="`text-${resolveUserRoleVariant(userData.general.role)}`"
                />
                <span class="align-text-top text-capitalize">{{ $t(textFirstUpper(userData.general.role)) }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('Country') }}</span>
            </th>
            <td class="pb-50">
              {{ $t(userData.info.country) }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('Mobile') }}</span>
            </th>
            <td>
              {{ userData.info.mobile }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import {
  onUnmounted,
} from '@vue/composition-api'
import useUserList from '../user-list/useUserList'
import userStoreModule from '../userStoreModule'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    deleteUser() {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          store.dispatch('user-store/deleteUser', this.$router.currentRoute.params.id)
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Deleted'),
                text: this.$i18n.t('Your data has been deleted'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.$router.push({ name: 'user-list' })
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response?.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response?.data.message)
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error?.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
            })
        }
      })
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'user-store'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      resolveUserRoleVariant, resolveUserRoleIcon, resolveUserStatusVariant, textFirstUpper,
    } = useUserList()
    return {
      avatarText,
      textFirstUpper,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
      .swal2-popup {
        background-color: $theme-dark-body-bg !important;
      }
  }
}
</style>
